export const allowlistAddresses = [

  ["0x0ffb9b96d5a17cefd82b9309f1be5baceca311e0",1],
  ["0x22ec717a36833ef61fe79e21412bddf4e143ee52",1],
  ["0x9f38d150384cc6cb4d95b40ac8b0d4207e164548",1],
  ["0x85e7fa0f1dfc325b3e496f25f2f0dc17998829f0",1],
  ["0x6f090e9d0c41a8ba699f906fbca6172a44ffcba9",1],
  ["0xb2b2b940e3e8cdf2e9f9ebb8dbecb28fa3d473da",1],
  ["0xa1a45a2b5fcdcdc590f5203d194e563ae6742e32",1],
  ["0xc740743cbbffabb69d28b678329977b575afc1dd",1],
  ["0x2bd8afa9bdfd2899326b833507bd4dd0bfd846df",1],
  ["0x9299bc158ba768f6c43d8503110ade0537ef43e9",1],
  ["0xe51484e17a214aad2e52697554e81f04d21ee88e",1],
  ["0xb577575a51927c83c9a30087dac9329b5d1c4571",1],
  ["0xedae9ec3e28352e74d1b519be088a7b59c891459",1],
  ["0xa0d8ddc7ec2249c6df227db8e6da21b6efee7f58",1],
  ["0xa33b2df047886c5282ba8f7f2b49d2af3c8ee699",1],
  ["0x16c0be20bb68a5930c7176f2f42ca82346b3246f",1],
  ["0x88897bc45dd540413fe5bd1bfd9e04125af3ecc3",1],
  ["0x09d3d71db36c0395e1d7f514e728f123357b03f2",1],
  ["0x612ca623e264782e3eae3e6efd589c2ff5a2b51c",1],
  ["0x80a711d2598d5c22704aab78977663a86432638a",1],
  ["0x2c19a07573c404bfd18a89ec6779029f4c75f628",1],
  ["0x9f56bff4459a58488d76df9e42a1c8fe48046f7d",1],
  ["0xd1b74e02e51ff3c92c7ba53f893f8c9828071d77",1],
  ["0x62d35af3365f76b883bc54d05ac1fdb6b5f6d694",1],
  ["0x5a5cc9dab5cfcaaba2316d8628a745ac60eedd47",1],
  ["0xd0bd462e4655d47445635eda0ce137c2acd976f6",1],
  ["0x826d434d6fb50a988a7823adc16db3fd11fa29e9",1],
  ["0xf8be90c1d610a3a1a8f7c6e56e00d2529ed1f9c0",1],
  ["0x751445320a27b13885084b63e1de615474b6b28a",1],
  ["0x1ff8d935c9887f69bb163fd68d3a614379cccc27",1],
  ["0x347a0ce4a345f442b1ef9e46bca76407cd3b8815",1],
  ["0x9e0696f09bb1e14c8d062e9c44ce36138f870d20",1],
  ["0x3243855e72f0bc21db47838e5607bc1ea23fb040",1],
  ["0x1a3326bf12589ee53b3f796c9d376ea3df60d459",1],
  ["0x5c3d97f9a3b7938ca043b3a671b4b05291a94823",1],
  ["0x3d663a4f09a00541ee7bb5094c8f56c7fa91a6e8",1],
  ["0xdcea2a0baef09252c30ba97d7f309f5ca4c2af46",1],
  ["0xb200e3edaed8ec5e58e1ca8b80838fe967ebc4e6",1],
  ["0x2f617fa1c80bdeb85fc01c8dddd2fefd5a13a960",1],
  ["0xc3f2f7d08ae59e0f6d22bd1c1ee7cd2f11cd21e4",1],
  ["0xf2466d553b1d0cdf6ede3e8c2d3652e7338d3c70",1],
  ["0xca30ba08dfecb451ce64bc765df1749185106bbf",1],
  ["0xf7f1c81f934278e8a20232ac6de3da238f26566f",1],
  ["0x740ee70dc977b00dc9613e2799aedb775432b09b",1],
  ["0x7b17c49d11ddd2d63e369e48aec6f0a6b6d192ed",1],
  ["0x756cf9df2d490c192243f047f808864c2369b825",1],
  ["0x69e9ec83cba73744fa44f4183e200e038320f4dc",1],
  ["0xcc6ae730ba628a81478e18b1c5e5b593f2c8d74e",1],
  ["0x1b66c6c91b7cf8015a354b01d6411a87161c1215",1],
  ["0x98b15ce0b2e123bc45e08287dccdce23e02bf63b",1],
  ["0xa17cc03654e43576fd890c3aeeb3670fb70dd540",1],
  ["0xf15b2d971b9b320d931b5264df47af3b4db82981",1],
  ["0xa58391a34e52f435b0d32e321857db28c5235a55",1],
  ["0xc78eeab7991ad02963fb1997f7aba9eb9d2027b5",1],
  ["0x0f1f87e9d573ffa81c71661b4a7838a89e4693f3",1],
  ["0xeffa0b6b2ed1a91c14c281f23eabc4e2d7ff6fcf",1],
  ["0x3a855960ced4f8b86f2644411dbacc5c973fc583",1],
  ["0xb8c2e9fad29e4bbda3832bf8d3300727bc22a70d",1],
  ["0xfe1532eb9a71431b205e5cdc16cb3c009b948d93",1],
  ["0x45015fd0f2fa60df12afaf6370b62a7e7f150b47",1],
  ["0x1c7f1b7650d505a1a10d25b05d0f7b9cbd41c5f7",1],
  ["0x7d3bcd42c6a7995b509c75fcb20fe0891b0e26dc",1],
  ["0x60fea19ec1e9e210fbf1bc5e5f19f4fdfdaf8281",1],
  ["0xa1a81412ae054e1f9c82586bbf2fe6aa39d256e4",1],
  ["0xdc9648bbd4fc4478feb7a81da25b516e285e5f6a",1],
  ["0x6a1a6090ce9c522796f632c0a5dbebdc11e8eaef",1],
  ["0x0931817f30842f4b67608cb6d2e623d502d6ab14",1],
  ["0x07109aca84117902f428a90a80f071f71b270bb0",1],
  ["0x493da0cde8cbe456d096d296b88550a703939354",1],
  ["0xac75248edcd608878324c6894427977f8ef8599d",1],
  ["0x68c99964b6149aac696efa5aae7518fc978054c8",1],
  ["0x14b59ef6dd76a78cadf77ffd99ba8e6358759744",1],
  ["0x95debaeb0c9eafe11fa5c7c96ab64dd4e436d769",1],
  ["0xb7d994657d400d434e84b11d02c935be69957657",1],
  ["0x9f3e593474fda86dd892e57b8646f23f0f80ad09",1],
  ["0xa01db1dd39f79a239038523d0fb51ddd1580139f",1],
  ["0x1112603845e343632a4fa185e1a37e0561065ae3",1],
  ["0xf4796c3a78080dad8b3f1fcb39f097fe46b7bc0f",1],
  ["0xb3538c1942b1d980f52b6c916c22964a1ae5e871",1],
  ["0xdeb3f6f80e1f9fc698990171a48578ecd2e42aef",1],
  ["0x0a11605280c54f62f4968dbd7078981006716355",1],
  ["0x3fe0c03461f1a74b7a9dd670ac077db1c33f5b72",1],
  ["0x005f9b4f14b994d69737f42176db734d0ad7053a",1],
  ["0x1a953bc7e8668b031d3dfc351cc3052aa264bd10",1],
  ["0x3bd99cb7265e946feb1fc29b8dba4334d33f8794",1],
  ["0x82e1e9666edac4eb98850ce2ef2cf9bfedf2183d",1],
  ["0x37d32679ce9245a3f67d4830cbb3453e85e705d3",1],
  ["0xa18d39c37a6e78b91b1f36fc7d6ed91fcdb597e3",1],
  ["0xa909427988bb1ea4924b522eddebb9b6fb29a2f1",1],
  ["0x6112b496e43a05e61797743a45640df13efcf053",1],
  ["0xf511cfab8f2dbedeed91afa8a3fc6246c18d4ee4",1],
  ["0x5bab2a78c18aa2bdcf6b045ab15850cf21e0fd20",1],
  ["0xb990378362f9e15b3bfa05206cca8f7145ca703c",1],
  ["0xe6fdb884bf7c69e31198dee9123f9e225afb57ae",1],
  ["0xfbf083f0d2a9092fdca9aee7430a44bef152d369",1],
  ["0xd24db0bfb387b0ed05f757e5f3b9ca1c59a82605",1],
  ["0x87481851f2cacfff8763f49bff57e7afe3ab87dc",1],
  ["0x4f6eca606167a45acd1968d9a4291b033855593f",1],
  ["0x51c9631896950a6fd0b03a709614fb75d891b3fa",1],
  ["0x231ac6d364d6160a1fa901c255c2169c9a8bde73",1],
  ["0xc1124f513e1ef19b0ae344808a188c87fb3fc0f9",1],
  ["0xf929ac599c54ab2320fbefcbbefba27dfe558607",1],
  ["0x41debb4704c681b403ba3b0f8e78e3fe2f29bcca",1],
  ["0x990743e2627c90d23c083566224391a72c7efd0a",1],
  ["0x24aae0a135985dc8ae6b8bac64051af645c2b3f9",1],
  ["0x6909fe2c20fea0e84077e2c26709a42fd482fab2",1],
  ["0xeb7a63737f75cd9b08829b57e7ea52803a221cf3",1],
  ["0x291f5cc17d317bac617c1beaab73a0985683e815",1],
  ["0x7e45c617350395ba24210c3b0f926f9973177b9f",1],
  ["0xc934544944145535428352f14ba0be654b6cdd19",1],
  ["0x9ad5f1545c1e25d3045c5f991fe40ef3d0ed069f",1],
  ["0xc0dc468bdd2126acf8d72dc6eb77949465784edb",1],
  ["0xbee7c22a4fcb8d9d6d24d6ed8b51457265526711",1],
  ["0xd290499aed3c25ca8d3426678fb28aa282cde3d0",1],
  ["0xc3d3e3da141ecce9cb2098a7d44355b9f0b26ea7",1],
  ["0xfd487911196048348e13a5933e25a8dfa1c64995",1],
  ["0x86eb8be678274ba4c47af17fa8511c3f9c631bbc",1],
  ["0x2a2f3d9a777038ce331ceeec14472cc6a37ccd62",1],
  ["0x44d354cd189ab5f6f621109a07a0d420a020569f",1],
  ["0x12c1f145107f53c7122cbf8ac372f9d61aef84eb",1],
  ["0xba522a7ee4d0d38daa93734bf4daf8fd4c998c95",1],
  ["0xf47d208056dbceb153084fb93926df96843f5b16",1],
  ["0x3c85d0a7f3d7057e121f3f85d61c2b7a17310866",1],
  ["0x6795cf3ebd8d30b170d4bfe8ef82b7170f8bc158",1],
  ["0x0aee9fe13a9fc369e4af5edf31054273c88bc6ac",1],
  ["0x8422075cf6daf10316ab867ce6b4db3d47d0458b",1],
  ["0xdb94454a0f213280321053cabd8a7848255bcbae",1],
  ["0xbe9e7f4eca05a83fd210c59db76be779e8003c68",1],
  ["0xa7b757e57d577e1df7f1dd278303ea74704e6f27",1],
  ["0xb686f2d1de4b85abccbea51c7e95244572ad3c1c",1],
  ["0xc7d44ca605ed597517ce47b6589cf6b0bd0e0252",1],
  ["0xcc24001414caf0c985e35167a2561994203af7a9",1],
  ["0x23b3e34f9a16644513890b3dd4f3694e5cff2861",1],
  ["0x77ae1bcaed594a687b0a2a03a594a7308ed24173",1],
  ["0xd4a1b612a444eab9ef105a877969d38cced4a63b",1],
  ["0xed8501a2d9e65ecdac99b63b82c9b168fadacca4",1],
  ["0xd6c8422839b7965dade45349f6bcea6b2963b480",1],
  ["0xa95e189d18b722d04f9a19c55f25383c58d5060c",1],
  ["0x1f5066f3a87c117075f57eb3e9839f3999977fdd",1],
  ["0x48147b2c543a0eea58da03f54a9da3aa828b1584",1],
  ["0xdba538633bf52b0cae66c42991fc94c4fe6cbeed",1],
  ["0xa96f20d8afc0794ba710aa1baa5411070ca6c061",1],
  ["0xc2153b4118399c0ad331ad2c1d2d34bc48ea6048",1],
  ["0xeec66737e685278e6a2c495a6fe4679a14c68f35",1],
  ["0x8feeb7be0384643ad69dd097a1990b36a721c012",1],
  ["0x7d76dfa754d2b0bea24801ace10fc343c01be0a1",1],
  ["0x6313fdbc70088df6474f00aec0ea38413351e32b",1],
  ["0xbab1a10d74da9943b593c3eadf95cd41b968a991",1],
  ["0x48ef11011ded807f3246bb8c2f4ce9426e09be88",1],
  ["0x43c9a7e362c6ad43896e962cec9a3096302b154e",1],
  ["0x28f14cbcbd988eb3e8791d68fed601af5eee41ae",1],
  ["0x6d5618388dc917134abbdcad7ae4071af9cb5a7d",1],
  ["0x21b5ad7ea02d44dd1e2ce38487da8781b84fd13a",1],
  ["0x5a47de3c14f027796b804d8d26d94b1e41546cc4",1],
  ["0x2911a31ae7d7cf4ee151976fcc7b27111a528b8f",1],
  ["0xa0eab382c09b38db4c21a0dbfa9282ff955acd52",1],
  ["0x89fc135b9edd480d4945afb36f67b609e73e1b90",1],
  ["0xa9d34bc7d9e571c9b37b061397133af1561e981a",1],
  ["0x51d8b206a7fa809c414e49596c060b397b9d5ad2",1],
  ["0x2b6af1ab9c05b665015f840745625e3621af8287",1],
  ["0x7a23883b26973eb32ae3ca6ccb29513421649a5e",1],
  ["0x1f358b18cbc2a9989642da26f080a4f92361c66d",1],
  ["0xfbc41168272707768a85706ea09feaa64680e712",1],
  ["0xc0eb9c32ee2fd27f4d009b308f644fca5109f764",1],
  ["0xece1d1ca2ccf6f877ca3e07a1f69a6e234f8784e",1],
  ["0x0d1de6507595f78bfb86fa1b3288e6fb848b7989",1],
  ["0x8d1181285dd480af581e9e34b3677a66d6d69343",1],
  ["0xe5fea09f8d0506ec709767b91533f736d63fb735",1],
  ["0x81d3d728dabfc2c939a45addc2642406fc9dd8c6",1],
  ["0x44f52599798e66d7f45f5a20fffb805cc33c65b4",1],
  ["0xe24fd9307e4c8234643e324b6e3fa8ba2f860280",1],
  ["0x45ccc59ddf78de3252379b9189d36728e55fa644",1],
  ["0xcc1f01220b9d9a2bcabb87da9cbc75e2b8a06b8b",1],
  ["0x46ee5d9395b6e74695d3a11036e6708b530dc07d",1],
  ["0xc5e97dd71bff9102d3ee9781de07c8cde8391b18",1],
  ["0x4e2fdf615c83abee1be2464415416a8011deab59",1],
  ["0x4cb0ecd531161d8d01a1eea84d48f4776a7ea795",1],
  ["0xc760fe8e0f361b629ce00da11408d2d08a93b58f",1],
  ["0x801d0ee471f5cc3a884cb99c36fca14b4afebf01",1],
  ["0x42b9fa98953b51e2c4a96722fb67460ea468d814",1],
  ["0xd0d5250a553d07f6622b19b04929f64e7876db93",1],
  ["0x1391b3b5918ab467bb0660b4384033c234ff7d41",1],
  ["0xf308cc36bd85f135b5e65c8b8ede781b1cbed7bc",1],
  ["0xa3f4c0b4d17cf346ef5c9d491e9036c032da1a77",1],
  ["0x037060c831cafe2d6ff763420f79ced5cc32feda",1],
  ["0xfe1fcf0d53614563e759bd137bfe264fac6eeec8",1],
  ["0x58608a7c9dac7b22da978a8c0a1c49802e822a70",1],
  ["0x32f2a7a720e7d1f8a4044ee358ae56fe4ac3e756",1],
  ["0x7cda64fb1f0c58f42c6e165ef69bab9324e2ac3e",1],
  ["0x93a9426951e26f019e61dff6d222648b9d12a8f4",1],
  ["0xdecf4b112d4120b6998e5020a6b4819e490f7db6",1],
  ["0x0194fc6d3a72d5bc37939f5fb53e220fc56f5cf6",1],
  ["0xc92dccd612e338ca28614446377eda1054a10485",1],
  ["0xc32ad2cf9c2c94186ea29c2d811e44dedfd72f45",1],
  ["0x67009159dd4feed5ec9e573d55912b41ec2d7615",1],
  ["0xc82934238d95e46722dcbcfc07bbc1880bdfd534",1],
  ["0xddf21318ca81f9dcb3f143b40b95c72328c46892",1],
  ["0xe763287cf9034eb7e262c7abd27afc8c89116cd9",1],
  ["0xa62ef7ab9f3fd15bccd32c9fc6d2321f5a46ec0a",1],
  ["0xb5a3112d496f51936f8c3a77b01593665f32d41e",1],
  ["0xfe0ae9d5bdf0077ce7c54ad2cef4d05049434973",1],
  ["0xf823ef32ad704f5d409e01aaca250840465c5b8e",1],
  ["0x4b0e401bade7878a62d6eb4996f3539c4a001037",1],
  ["0xcbd42c0b2621431715f915785d401b967fe03f34",1],
  ["0x03c24d6910e612ca0c2933b798e26f09223ec13f",1],
  ["0x87369e67068197d9a164884c4541bd1503ccfb8f",1],
  ["0xcc24f1cf1ae36c8c6032b7914cd861230cba01b4",1],
  ["0x50f72106781d2e0c254776edc3a20aa9be6d76c3",1],
  ["0x1972de92bad30239f5a225c1523bd641c0fc29a7",1],
  ["0x56f7b803f3880d3f2146d8d547eb1c35032b8986",1],
  ["0x9de62c4476f07003547edf75330bf30303cad90f",1],
  ["0x53596b3f75c8c529115b1fa9eb66f0fdb3c12d57",1],
  ["0x40479d26fa23be5b23608329aa8d4dafcc4b5100",1],
  ["0xbdba2bf81c6cee8d8e7365ef4cbcaedf33c80329",1],
  ["0xb17dcb47afbd56d1395afbe684536796af1877c3",1],
  ["0x48b4e4a5f3f9b8ab6ff66ba31b30909ab97a7cbc",1],
  ["0x3e1dc89ab1e3bb8a64bb0f65b65b404f1bf708c3",1],
  ["0xe00b46dffeecf08217312d32e69125d07987378f",1],
  ["0x00317e3960feb35afcf09ff76c0ab38bb526a48a",1],
  ["0x55cf7856c7fd1cb488aacc7adbe241db6208e2f0",1],
  ["0x65f5f7086d5f5658c0e3e81c53a1b01f1bccb3ec",1],
  ["0x0c7d841966696af4d1aff9a56424ab938626a443",1],
  ["0xa5e1a7d9f517bfcb71cbfe18c9a4bbcdd7edfbf6",1],
  ["0xb5d7cfe079b6a192a072b390fe5d0cf99c5e54d2",1],
  ["0x353e599d2f8d1509681c24baff6f97a7917ad992",1],
  ["0xdd11a8a06c16fa9292c0fb80cc7964c12d467c3f",1],
  ["0x8e6e8f9f6c388332c321a8f4ae4e6242f084eb22",1],
  ["0x004fb342f4b36e504f667a4fe6932e0a1e20e529",1],
  ["0x75c4d7544dc881596e3679345a7e915f62171dea",1],
  ["0x0bc7fd075906c3a14428f9745743b5945d556896",1],
  ["0x687031aa5bdf89437e5b96e8380847471d5f2db1",1],
  ["0xef3414015e61e834b27d0ae177261d98fe11905b",1],
  ["0xb3936e5b1d562d0bd6e4028f323740278e67a41b",1],
  ["0x03eeba1287dbbc6e5a10e3c916976a27ad00fe29",1],
  ["0x5d2684333aa3cf5c508d4c4cdb1e25aedf46f50d",1],
  ["0xfd46ad53497022d734d2f2bf29622db0214111f1",1],
  ["0xed7aba2eea8a930791873620019ed49eb23a9f97",1],
  ["0x1eaa4c4da20a7b944fd2788f0148b7b1c98495f2",1],
  ["0x1fc21c4ebdf59453cb881c84cb569c228690ad1f",1],
  ["0x3342e89ec9e8560c2892c331909da2fe56f20f59",1],
  ["0x252095abf4629e522e4c1a1edbc3264683849fb6",1],
  ["0xb1223fad14c38ea016dcfe9fc7b2dc6d72ea51d9",1],
  ["0x2065685879367ff787f19bc0a2bbae2e284dfce4",1],
  ["0x423b55048c6a379d154d24b7ce4e9c93527ecae3",1],
  ["0x7dad42f6a5ebe7f0f91a23927e945538a4a9e202",1],
  ["0x5a41d3762444e516e90476057d3d2fb5858e719f",1],
  ["0x11a3c12c8e6672f8504c0cbf9f9165093ee97b4e",1],
  ["0x91efd32e53253cc56468981c1d13361ad0d5cb8d",1],
  ["0xbe82ea5eee7f0ac2d5d8fd28f589b7f82321d75b",1],
  ["0xa843c9ec0bb93303b78a3b99ea0e5e3fb1bbfd4a",1],
  ["0xdd6e74c1b8e41b5e113e31f5618c3995427fa790",1],
  ["0x3f3b1a82bdf41e7fbaf7ab1d4da42d1b235b14e6",1],
  ["0x8251b5364f8e9ceb25d93a9a4d0d34989afad499",1],
  ["0x794dbbe0a0ede6123cf22f3954ec49868c36c787",1],
  ["0x79f29d23c77a6a6ff087f6448d54f370f7a199f2",1],
  ["0xc2df25ecec66f3b02485e3b402382991807cbea1",1],
  ["0x8327773dd2c1e421618560d183cd1f41c72f937e",1],
  ["0x2ab70125fb07fd5af722efa68d4f8f2f846e8b1e",1],
  ["0xbd1eb2c0ab87ca8cac7d5de1dbe1644a5633e692",1],
  ["0xc5568c25e4b17fff93ab17f12e2b0da8d79dab84",1],
  ["0xf78ba828973c5406924342f1d6865d2214cd3d6e",1],
  ["0x1bde49730233aa5f41760a37fc5054a4d9896149",1],
  
]